import { useState } from "react"

type PageLink = {
    text: string,
    link: string
}

type PageWithLinks = {
    rootLink: string,
    links: Array<PageLink>
}


const ProtocoloForm = () => {

    const [url, setUrl] = useState<string>('')
    const [urls, setUrls] = useState<Array<string>>([])
    const [rendered, setRendered] = useState<Array<PageWithLinks>>([])
    const [error] = useState<string | undefined>(undefined)
    const [fetching] = useState<boolean>(false)

    const removeItem = (idx: number) => {
        const items = urls.filter((item, itemIdx) => itemIdx !== idx)
        
        setUrls(items)
    }

    const addUrl = () => {
        const newUrls = url.split("\n")
        const allUrls = [...urls, ...newUrls]
        setUrls(
            allUrls
        )

        setUrl('')
    }

    const fetchIt = async function(rootLinks: Array<string>): Promise<Array<PageWithLinks>> {
        let resp = await fetch("http://localhost:8000/protocolo/info", 
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    urls: [...rootLinks]
                })
            })
    
        const response = await resp.json()

        const items: Array<PageWithLinks> = response.map((item:any) => {
            return {
                rootLink: item.rootLink,
                links: item.items.map((link: any) => {
                    return {
                        text: link.text,
                        link: link.link
                    }
                })
            }
        })

        return items
    }

    const pasteUrl = async () => {
        try {
            const copiedUrl = await navigator.clipboard.readText()

            setUrls([
                ...urls,
                copiedUrl
            ])
        } catch(err) {
            console.error(err)
        }
    }

    const copyToClipboard = (item: PageWithLinks) => {
        try {
            let text = ""
            text += "- [ ] " + item.rootLink + "\n";

            item.links.forEach((link: any) => {
                text += "    - [ ] [" + link.text + "](" + link.link + ")\n";
            })
    
            navigator.clipboard.writeText(text)
        } catch(err){
            console.error(err)
        }
    }

    const requestInfo = async () => {
        const items = await fetchIt([
            'https://www.protocolo.org/social/etiqueta-social',
            'https://www.protocolo.org/social/conversar-hablar/'
        ]);

        const splitItems: Array<PageWithLinks> = []

        const chunkSize = 40;
        
        items.forEach((item: PageWithLinks) => {
            for(let x=0;x<item.links.length;x+=chunkSize) {
                const chunk = item.links.slice(x, x + chunkSize)
                console.log(chunk)
                const part: PageWithLinks = {
                    rootLink: item.rootLink + " (" + x + " - " + (x + chunk.length) + ")",
                    links: chunk
                }
                splitItems.push(part)
            }
        })

        setRendered(splitItems)
    }
    
    return (
        <div className="container">
            <div className="row mb-2">
                <ol>
                {
                    urls.length > 0 && urls.map((item, idx) =>
                    <li key={idx}>
                        <span>{item}</span>
                        <button className="btn btn-danger" onClick={ (e) => removeItem(idx)}>X</button>
                    </li>
                    )
                }
                </ol>
            </div>

            <div>
                <textarea
                    rows={10} cols={80}
                    className="col-12"
                    value={url} 
                    onChange={e => setUrl(e.target.value)} />
            </div>
            <div className="row mb-2">
                <button
                    className="btn btn-primary col-1 ml-4"
                    onClick={() => addUrl()}
                    >+</button>
                <button
                    className="btn btn-primary col-1"
                    onClick={() => pasteUrl()}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard" viewBox="0 0 16 16">
                            <path key="1" d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z"/>
                            <path key="2" d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z"/>
                        </svg>
                    </button>
            </div>
            <div className="row mb-2">
                <button className="btn btn-primary"
                    onClick={() => requestInfo()}>
                    {fetching && (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    )}
                    Submit
                </button>
            </div>
            <div>
                {rendered.length > 0 && rendered.map(item => (
                    <p key={item.rootLink}>
                        {item.rootLink}
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard" viewBox="0 0 16 16"
                            onClick={(e) => copyToClipboard(item)}
                        >
                            <path key="3" d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z"/>
                            <path key="4" d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z"/>
                        </svg>
                    </p>
                ))}
            </div>
            {error && (
                <div className="row">
                    {error}
                </div>
            )}
        </div>
    )
}

export default ProtocoloForm